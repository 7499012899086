import React, { useState } from "react";
import users from "./smol_overlap.json";
import User from "./user";
import twit from "twit";

export const TwitterAPI = new twit({
  consumer_key: "607528502-du8su7hqdOehH1gFGWphMUNHevmNMa438AzvtoUk",
  consumer_secret: "msxs4PYMkx4MMaakfQb6zvKOewoMFWOwvaPAOIZnRWGzG",
  access_token: "1nwg2gW3LR2C7l7fKPF3r8PYD",
  access_token_secret: "BzmwB6QSVkV22rAa1QGbdVfeY1KExzIT1dZe56bcBGab0cXCCm",
  timeout_ms: 60 * 1000 // optional HTTP request timeout to apply to all requests.
});

const pageSize = 50;

// const Pager = (data, pageSize) => {
//   let cache = {};
//   ("users/lookup");
//   return page => {
//     // Return a promise, resolve instantly if cache hit

//     return new Promise((resolve, reject) => {
//       cache[page] && resolve(cache[page]);
//       TwitterAPI.get(
//         "users/lookup",
//         { id: data.slice(page * pageSize, (page + 1) * pageSize) },
//         (err, data, response) => {
//           return err ? reject(err) : resolve((cache[page] = data));
//         }
//       );
//     });
//   };
// };

// const userPager = Pager(users, pageSize);

export default () => {
  let [state, updateState] = useState({
    page: 0,
    loading: false
  });
  console.log(users);
  let data = users.slice(state.page * pageSize, (state.page + 1) * pageSize);
  // Request Twitter Users
  let content = state.loading ? (
    <div>Loading</div>
  ) : (
    <div>
      {data.map(user => (
        <User user={user} />
      ))}
    </div>
  );

  return (
    <div>
      <div>Oracel Engine</div>
      <div>
        <div onClick={() => updateState({ page: state.page - 1 })}>
          Backwards
        </div>
        <div onClick={() => updateState({ page: state.page + 1 })}>
          Forwards
        </div>
      </div>
      <div>{content}</div>
    </div>
  );
};
