import React, { useState } from "react";

import styles from "./styles/user.scss";

export default ({ user }) => {
  return (
    <div>
      <div className={styles.userPill}>
        <div>{user.name}</div>
        <button onClick={() => window.open(`http://twitter.com/${user.name}`)}>
          Follow
        </button>
      </div>
    </div>
  );
};
